/* Vertical Offset Bottom */

.vert-offset-top-12 {
    margin-top: 12em;
}

.vert-offset-top-11 {
    margin-top: 11em;
}

.vert-offset-top-10 {
    margin-top: 10em;
}

.vert-offset-top-9 {
    margin-top: 9em;
}

.vert-offset-top-8 {
    margin-top: 8em;
}

.vert-offset-top-7 {
    margin-top: 7em;
}

.vert-offset-top-6 {
    margin-top: 6em;
}

.vert-offset-top-5 {
    margin-top: 5em;
}

.vert-offset-top-4-7 {
    margin-top: 4.7em;
    padding-left: 0;
}

.vert-offset-top-4 {
    margin-top: 4em;
}

.vert-offset-top-3 {
    margin-top: 3em;
}

.vert-offset-top-2 {
    margin-top: 2em;
}

.vert-offset-top-1-5 {
    margin-top: 1.5em;
}

.vert-offset-top-1 {
    margin-top: 1em;
}

.vert-offset-top-0 {
    margin-top: 0em;
}


/* Vertical Offset Bottom */

.vert-offset-bottom-12 {
    margin-bottom: 12em;
}

.vert-offset-bottom-11 {
    margin-bottom: 11em;
}

.vert-offset-bottom-10 {
    margin-bottom: 10em;
}

.vert-offset-bottom-9 {
    margin-bottom: 9em;
}

.vert-offset-bottom-8 {
    margin-bottom: 8em;
}

.vert-offset-bottom-7 {
    margin-bottom: 7em;
}

.vert-offset-bottom-6 {
    margin-bottom: 6em;
}

.vert-offset-bottom-5 {
    margin-bottom: 5em;
}

.vert-offset-bottom-4 {
    margin-bottom: 4em;
}

.vert-offset-bottom-3 {
    margin-bottom: 3em;
}

.vert-offset-bottom-2 {
    margin-bottom: 2em;
}

.vert-offset-bottom-1 {
    margin-bottom: 1em;
}

.vert-offset-bottom-0 {
    margin-bottom: 0em;
}


/** login page css **/

.margin-left {
    margin-left: -30px !important;
}

.form-create-account {
    max-width: 800px !important;
}

.form-signin {
    /*    max-width: 330px;*/
    max-width: 360px;
    padding: 15px;
    margin: 0 auto;
    border: 1px #ddd solid;
    background: linear-gradient(0deg, #eeeeee, #fefefe);
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.login-container {
    padding: 40px 0px;
}

.btn-login {
    background: #666666;
    background-image: linear-gradient(to bottom, #666666, #000000);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    filter: progid: DXImageTransform.Microsoft.Gradient(startColorstr='#666666', endColorstr='#000000');
}

.btn-sign-up {
    background: #666666;
    background-image: linear-gradient(to bottom, #ffffff, #cccccc);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    margin: 0px 10px;
    filter: progid: DXImageTransform.Microsoft.Gradient(startColorstr='#ffffff', endColorstr='#cccccc');
}

.btn-sign-up:hover,
.btn-sign-up:focus {
    background: #ffffff;
    text-decoration: none;
    color: #000000;
}

.btn-sign-up:first-child {
    margin-left: 0px;
}

.form-password h2 {
    font-size: 13px;
}

.login-footer {
    background: #f5f5f5;
    padding: 25px;
    border-top: 1px solid #eeeeee;
}

.login-footer ul li a {
    text-decoration: underline;
}

.footer-terms,
.footer-terms p {
    font-size: 11px;
}

#popover-content label {
    background: red;
}


/** login page css **/


/** Signup page css **/

.price {
    font-size: 1.2em;
}

.panel {
    -webkit-transition-property: scale;
    -webkit-transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
}


/*.panel:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.5);
    -moz-box-shadow: 0 0 10px rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.5);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
}*/

.panel-default>.panel-heading {
    color: #fff;
    border-color: #ddd;
    filter: progid: DXImageTransform.Microsoft.Gradient(startColorstr='#dc2028', endColorstr='#af0d1f');
    background: linear-gradient(to top, #dc2028, #af0d1f);
}

span.label.label-primary,
span.label.label-danger {
    font-size: 14px;
}


/*.main_menu ul li:last-child{
    cursor: pointer;
}*/


/*.main_menu ul li:nth-child(7){
    margin-right: 30px;

}*/


/** Signup page css **/


/*span.cart-text{
    color: red;
    margin-left: -8px;
    margin-top: -18px;
    padding-top: -33px;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
    display: inline-block;
}*/


/** for Mobile Menu ****/

.navbar-toggle>span.fa.fa-bars {
    font-size: 34px;
    color: #ffffff;
}

.mobile-menu .nav>li a {
    color: #ffffff;
}

.mobile-menu .nav>li a:hover,
.mobile-menu .nav>li a:active,
.mobile-menu .nav>li a:visited {
    background: #333333;
    color: #ffffff;
}


/** for Mobile Menu ****/

article .main-header {
    background: #000;
    max-height: 35px;
}

.sidebar  {
    margin-top: 0px;
}

.content-wrapper,
.right-side {
    background-color: #ffffff !important;
}

.main-header .sidebar-toggle {
    padding: 8px 10px !important;
}

a.sidebar-toggle {
    color: #ffffff;
}

.content-header>h1 {
    font-size: 18px !important;
}

.content-header>div.alert-dismissable,
.alert-dismissible {
    padding-right: 0px;
}

.main-sidebar,
.left-side {
    border-right: 1px solid #f5f5f5;
    width: 330px !important;
    z-index: 0 !important;
}

.content-wrapper {
    min-height: auto !important;
}

.content-wrapper:not(.custom),
.content-wrapper.custom .content .content-div,
.right-side,
.main-footer {
    margin-left: 330px !important;
}

.content-wrapper.custom .content .content-div .error-content {
    margin-left: -330px !important;
}


/*Part view*/

.sidebar-menu li>a {
    /*    color:#333;*/
}

.sidebar-menu .treeview-menu>li>a {
    text-transform: capitalize;
}


.article-container {
    margin-bottom: -30px;
}

.main-header>.navbar {
    margin-left: 0px !important;
}

.cart {
    margin-top: 20px;
}

.cartThumbnail img {
    height: 60px;
}

.cartThumbnail p {
    font-size: 16px;
    font-weight: bold;
}

.cartThumbnail a {
    margin-bottom: 5px;
    margin-left: 0px;
}

.cartThumbnail div {
    height: 200px;
}

.carousel-example-generic {
    background-color: black;
}

.homeCarousel div,
.homeCarousel a {
    color: white;
}


/** Contact Page **/

.form-red {
    border: 1px solid #ccc;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 0 0 20px
}

.form-red h5 {
    margin-top: 0;
    padding: 10px;
    color: #fff;
    text-shadow: 0 1px 1px #000;
    font-size: 13px;
    font-weight: bold;
    background: linear-gradient(to bottom, #dc2028 0%, #af0d1f 100%);
}

.form-red .row {
    margin-bottom: 10px!important
}


/*   .form-red .controls .help-inline {
   margin-top: -9px;
   padding-left: 0
   }*/

.form-padding {
    padding: 20px 20px 20px 20px;
}

#search-btn {
    height: 34px;
}

@media (min-width: 1024px) {
    .mobile-menu>.navbar-collapse.collapse {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .ctt-container .box {
        width: 85%;
    }
    .mobile-menu>.navbar-collapse.collapse .nav .navbar ul li {
        border: 1px solid black;
    }
    .nav>li>a:focus,
    .nav>li>a:hover {
        border: 1px solid black;
    }
    .privacy-policy .panel-body>p,
    .terms-conditions .panel-body>p {
        margin: 15px 15px 15px 25px;
    }
    .navbar-toggle {
        display: block !important;
    }
    .ui-datatable .ui-datatable-thead>tr>th:nth-child(2) {
        width: auto !important;
    }
    .mat-tab-label {
        min-width: 0 !important;
        white-space: nowrap;
    }
    .ui-accordion {
        margin-top: 1em !important;
    }
}

@media ( max-width :480px) {
    .ctt-container .box {
        width: 100%;
    }
    .esn-modal {
        width: 95% !important;
        left: 2% !important;
    }
    .place-holder-hide::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        visibility: hidden;
        color: red !important;
    }
    .place-holder-hide::-moz-placeholder {
        /* Firefox 19+ */
        visibility: hidden;
    }
    .place-holder-hide:-ms-input-placeholder {
        /* IE 10+ */
        visibility: hidden;
    }
    .place-holder-hide:-moz-placeholder {
        /* Firefox 18- */
        visibility: hidden;
    }
    .iframe-video {
        width: auto;
        margin-left: 10%;
    }
    .btn-view-all {
        margin-top: 1em;
        margin-left: 1em;
    }
    .data-table-header>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(2),
    .data-table-header>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(3),
    .data-table-header>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(4),
    .data-table-header>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(5) {
        word-wrap: break-word;
        font-size: 8pt;
    }
    .data-table-header>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(2),
    .data-table-header>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(3),
    .data-table-header>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(4),
    .data-table-header>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(5) {
        width: auto !important;
        word-wrap: break-word;
        font-size: 8pt;
    }
}

@media (min-width: 768px) {
    /*    .mobile-menu > .navbar-collapse.collapse {
            display: none !important;
    
        }*/
    .navbar-nav {
        margin: 0;
        float: right!important;
    }
}

@media all and (min-width: 1023px) and (max-width: 1440px) {
    .content-wrapper {
        min-height: auto !important;
        background-color: #ffffff !important;
    }
    .main-sidebar {
        z-index: inherit;
        border-right: 1px solid #D0D0D0;
        width: 25%;
    }
    .sidebar-menu .treeview-menu>li>a {
        padding: 5px 5px 5px 15px;
        display: block;
        font-size: 14px;
    }
    .content-wrapper.custom .content .search-content {
        margin-left: 75px !important;
        margin-right: 25px;
    }
    .content-wrapper:not(.custom),
    .content-wrapper.custom .content .content-div,
    .right-side,
    .main-footer {
        margin-left: 350px !important;
    }
    .content-wrapper.custom .content .content-div .error-content {
        margin-left: -350px !important;
    }
}

@media(max-width: 1200px) {
    .content-wrapper {
        min-height: auto !important;
        background-color: #ffffff !important;
    }
    .main-sidebar {
        z-index: inherit;
        border-right: 1px solid #D0D0D0;
    }
    .sidebar-menu .treeview-menu>li>a {
        padding: 5px 5px 5px 15px;
        display: block;
        font-size: 14px;
    }
    .content-wrapper:not(.custom),
    .content-wrapper.custom .content .content-div,
    .right-side,
    .main-footer {
        margin-left: 350px !important;
    }
    .content-wrapper.custom .content .content-div .error-content {
        margin-left: -350px !important;
    }
}


/*@media only screen and (max-width: 992px) {*/

@media all and (min-width: 992px) and (max-width: 1024px) {
    .content-wrapper {
        min-height: auto !important;
        background-color: #ffffff !important;
    }
    .main-sidebar {
        z-index: inherit;
        border-right: 1px solid #D0D0D0;
    }
    .sidebar-menu .treeview-menu>li>a {
        padding: 5px 5px 5px 15px;
        display: block;
        font-size: 14px;
    }
    .content-wrapper.custom .content .search-content {
        margin-left: 90px !important;
        margin-right: 40px;
    }
    .content-wrapper:not(.custom),
    .content-wrapper.custom .content .content-div,
    .right-side,
    .main-footer {
        margin-left: 350px !important;
    }
    .content-wrapper.custom .content .content-div .error-content {
        margin-left: -350px !important;
    }
}

@media (max-width: 320px) {
    .iframe-video {
        width: auto;
        margin-left: 0;
    }
    .ui-widget-header-mobile {
        border: 1px solid;
    }
}

@media (max-width: 767px) {
    .content-wrapper,
    .content-wrapper.custom .content .content-div,
    .content-wrapper.custom .content .content-div .error-content,
    .right-side,
    .main-footer {
        margin-left: 0 !important;
    }
    .content-wrapper {
        min-height: none !important;
    }
}

@media ( max-width :767px) {
    .main-sidebar,
    .left-side {
        width: 230px !important;
    }
    .sidebar-menu li>a {
        font-size: 12px;
    }
    .sidebar-menu .treeview-menu>li>a {
        font-size: 11px;
    }
}


/**************/


/******Five Column********/

.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5,
.col-xs-2-5,
.col-sm-2-5,
.col-md-2-5,
.col-lg-2-5,
.col-xs-3-5,
.col-sm-3-5,
.col-md-3-5,
.col-lg-3-5,
.col-xs-4-5,
.col-sm-4-5,
.col-md-4-5,
.col-lg-4-5,
.col-xs-1-6,
.col-sm-1-6,
.col-md-1-6,
.col-lg-1-6 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-1-5 {
    width: 20%;
    float: left;
}

.col-xs-2-5 {
    width: 40%;
    float: left;
}

.col-xs-3-5 {
    width: 60%;
    float: left;
}

.col-xs-4-5 {
    width: 80%;
    float: left;
}

.col-xs-1-6 {
    width: 16.6%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-1-5 {
        width: 20%;
        float: left;
    }
    .col-sm-2-5 {
        width: 40%;
        float: left;
    }
    .col-sm-3-5 {
        width: 60%;
        float: left;
    }
    .col-sm-4-5 {
        width: 80%;
        float: left;
    }
    .col-sm-1-6 {
        width: 16.6%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-1-5 {
        width: 20%;
        float: left;
    }
    .col-md-2-5 {
        width: 40%;
        float: left;
    }
    .col-md-3-5 {
        width: 60%;
        float: left;
    }
    .col-md-4-5 {
        width: 80%;
        float: left;
    }
    .col-md-1-6 {
        width: 16.6%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-1-5 {
        width: 20%;
        float: left;
    }
    .col-lg-2-5 {
        width: 40%;
        float: left;
    }
    .col-lg-3-5 {
        width: 60%;
        float: left;
    }
    .col-lg-4-5 {
        width: 80%;
        float: left;
    }
    .col-lg-1-6 {
        width: 16.6%;
        float: left;
    }
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
}


/******Five Column********/


/******System view********/

.grid-btn a {
    font-size: 25px;
    cursor: pointer;
}

.box-profile img {
    height: 150px;
}

.box-profile ul li span {
    color: red;
}


/******part detail********/

.with-border a {
    /*    background: #333;*/
    background: #ffffff;
}

.with-border-btn a {
    background: #333;
}

.part-catalog-carousel>.carousel-indicators li {
    background-color: black;
}

.part-catalog-carousel>.carousel-inner>.item>img {
    height: 325px;
}

.part-catalog-carousel>.carousel-inner>.item>.carousel-caption {
    color: #333
}

.part-catalog-carousel>.carousel-control.right,
.part-catalog-carousel>.carousel-control.left {
    color: #333
}

.graphic1>a {
    background-color: #333;
    border: none;
}


/******part detail********/

.terms-condition .panel-body,
.privacy-policy .panel-body {
    padding: 10px 5px 5px 15px;
}

.media-body,
.media-left,
.media-right {
    vertical-align: middle !important;
}

#nav li:hover {
    z-index: 2;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none !important;
}

.fa-file-pdf-o {
    color: red;
}

.homeCarousel>.item>img-xhr>div>img,
.homeCarousel>.item>a>img-xhr>div>img {
    display: block !important;
    /*    height: 400px;*/
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    line-height: 1 !important;
}

.about-us-info li {
    padding: 0px 0px 1px 0px;
}

.about-us-info h4 {
    line-height: 15px;
}

.media-banner>a>img {
    display: block;
    height: 400px;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    line-height: 1;
}

.announcements-feeds {
    margin-bottom: 1em;
    border: 1px solid #f7f7f9;
    padding: 2em;
}

.announcements-feeds img {
    height: 310px;
}

.promotions-container div>div>a {
    cursor: pointer;
}


/******added css for header*********/

.nav>li>a:hover,
.nav>li>a:focus {
    margin-bottom: 0px !important;
    color: #ffffff;
}

.navbar-nav .divider {
    border-right: 1px solid #e48f92;
    height: 20px;
    top: 0.7em;
}

.navbar-nav .divider.border-hidden {
    border-right: 1px solid transparent;
}

.navbar-nav>li>a {
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.main-menu {
    display: inline-block;
    position: relative;
    right: -25px;
    bottom: 25px;
    top: 0em;
    z-index: 99;
}

.main-menu>ul {
    margin-bottom: 0;
    margin-left: 0;
}

.navbar {
    margin-bottom: 0px !important;
}


/***************/

.wrapper {
    min-height: 1050px !important;
    overflow-x: initial !important;
    /* overflow-y: inherit !important; */
}

div.card .engInfo {
    display: none;
}

div.card:hover .engInfo {
    display: block;
}

div.card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /*    position: absolute;*/
    z-index: 1;
    background-color: white;
    margin-bottom: 10px;
    position: initial;
    cursor: pointer;
}

.custom-nav li>a:hover,
.custom-nav li>a:focus {
    background: #f7f7f7 !important;
    cursor: pointer;
}

p.primary-cta {
    color: #fff !important;
}

a.active-link {
    background: #007C7C;
    color: #ffffff;
}

.gsn-data .table>thead>tr>th {
    text-align: center;
}

.sidebar-form input:focus {
    border-color: #999999 !important;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.parts-catalog-optionview .table>tbody>tr>td a {
    cursor: pointer;
    text-transform: uppercase;
}


/*****Card CSS********/

.w3-card-2,
.w3-example {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)!important
}

.w3-card-4,
.w3-hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important
}

.w3-card-8 {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)!important
}

.w3-card-12 {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19)!important
}

.w3-card-16 {
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21)!important
}

.w3-card-24 {
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22)!important
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
    color: #000!important;
    background-color: #f1f1f1!important
}

.w3-container:after,
.w3-container:before,
.w3-panel:after,
.w3-panel:before,
.w3-row:after,
.w3-row:before,
.w3-row-padding:after,
.w3-row-padding:before,
.w3-cell-row:before,
.w3-cell-row:after,
.w3-topnav:after,
.w3-topnav:before,
.w3-clear:after,
.w3-clear:before,
.w3-btn-group:before,
.w3-btn-group:after,
.w3-btn-bar:before,
.w3-btn-bar:after,
.w3-bar:before,
.w3-bar:after {
    content: "";
    display: table;
    clear: both
}

.w3-bar-block .w3-dropdown-hover .w3-button,
.w3-bar-block .w3-dropdown-click .w3-button {
    width: 100%;
    text-align: left;
    background-color: inherit;
    color: inherit;
    padding: 6px 2px 6px 16px
}

.w3-dark-grey,
.w3-hover-dark-grey:hover,
.w3-dark-gray,
.w3-hover-dark-gray:hover {
    color: #fff!important;
    background-color: #616161!important;
}

.w3-block {
    display: block;
    width: 100%
}

.w3-btn,
.w3-btn-block,
.w3-button {
    border: none;
    display: inline-block;
    outline: 0;
    padding: 6px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none!important;
    color: #fff;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    white-space: nowrap
}

.w3-margin-left {
    margin-left: 16px!important
}

.w3-margin-right {
    margin-right: 16px!important;
}

.w3-circle {
    border-radius: 50%!important;
}

.w3-left {
    float: left!important;
}

.w3-right {
    float: right!important
}


/*****Card CSS********/


/*****responsive table********/

@media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    #no-more-tables tr {
        border: 1px solid #ccc;
    }
    #no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: left;
    }
    #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
        content: attr(data-title);
    }
}


/*****responsive table********/

.ui-tabview-left>.ui-tabview-nav {
    height: auto !important;
}

.ui-tabview .ui-tabview-panel {
    border: 1px !important;
    margin: -1em 0em 0em 0em !important;
}

.ui-tabview .ui-tabview-nav>li.ui-state-default {
    cursor: pointer !important;
}

.table>thead>tr>th {
    background: #ebedf0;
    border-color: #d9d9d9;
}

.ui-widget-header {
    border: 0px !important;
    background: none !important;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    text-align: center;
}

.data-plate th {
    width: 45%;
}

.add-on .input-group-btn>.btn {
    border-left-width: 0;
    left: -2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.add-on input {
    box-shadow: 0 3px 17px -2px grey;
}

.ui-treetable tbody td a:hover,
.ui-treetable tbody td a:active {
    color: #dd4b39;
    cursor: pointer;
}

.ui-treetable tbody td a {
    color: #337ab7 !important;
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
    margin-right: -1px;
}

.table-col a {
    color: #0275d8 !important;
    cursor: pointer;
    text-transform: uppercase;
}

.promotions:not(li)>ul>li {
    list-style-type: square;
    margin-left: 2%;
}

.promotions-table>.table>thead>tr>th,
.promotions-table>.table>tbody>tr>td {
    /*    width: 25% !important;*/
}

.promotions-table>table {
    width: auto;
}

.mat-progress-bar-buffer {
    background: #8FC966 !important;
}

.mat-progress-bar-background {
    background: #8FC966 !important;
}

.mat-progress-bar-fill::after {
    background: #547b38 !important;
}

.mat-ink-bar {
    background: none !important;
}

.mat-tab-label {
    border-top: 2px solid #007C7C !important;
    border-right: 2px solid #007C7C !important;
}


/*
.mat-tab-label:active{
    background-color: #007C7C  !important;
    color: #ffffff;
}

.mat-tab-label:focus {
    background-color: #007C7C  !important;
    color: #ffffff;
}*/

.mat-tab-label-active {
    background-color: #007C7C !important;
    color: #ffffff !important;
    opacity: 1 !important;
}

.data-plate-header {
    background: #ebedf0 !important;
    text-transform: uppercase;
    font-weight: bold;
}

.data-table-header {
    display: inline-block !important;
    background: #f6f7f9 0 0 repeat-x;
    background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f7f9), color-stop(100%, #ebedf0));
    background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
    background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
    background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
    background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}

.col-icon {
    width: 40px !important;
}

.accordion-custom {
    border: 1px solid #007C7C;
}

.ui-accordion .ui-accordion-content {
    border: 1px solid #ddd !important;
}

.promotions-container img-xhr div img {
    height: 225px !important;
}

.ces-table-container>thead>tr:first-child>th {
    background: #ffffff;
}

.promotions-mobile a {
    cursor: pointer;
}


/*.ui-datatable .ui-datatable-thead > tr > th:nth-child(2) {
    width: 15%;
}*/

.custom-select-page {
    float: right;
    padding: 4px 10px;
    border-bottom: 0 none;
    margin: 8px 4px 0 0;
}

.table-container-heading a {
    cursor: pointer;
}

.navbar-template {
    padding: 40px 15px;
}

@media (min-width: 767px) {
    .navbar-nav .dropdown-menu .caret {
        transform: rotate(-90deg);
    }
}


/*** generic buttons **********/

.btn-default-1 {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-default-1:hover,
.btn-default-1.hover {
    background-color: #fff;
}

.btn-default-1:active,
.btn-default-1.active {
    color: #333;
    background-color: #fff;
    border-color: #adadad;
}

.btn-default-1:active:hover,
.btn-default-1.active:hover,
.btn-default-1:active:focus,
.btn-default-1.active:focus,
.btn-default-1:active.focus,
.btn-default-1.active.focus {
    color: #333;
    background-color: #fff;
    border-color: #8c8c8c;
}

.btn-default-1:hover {
    color: #333;
    background-color: #fff;
    border-color: #adadad;
}

.btn-group-1 {
    padding: 5px;
}

.btn-block-1 {
    width: 140px;
}

.btn-primary-1 {
    color: #fff;
    background-color: #007C7C;
    border-color: #ccc;
}

.upfit-btn,
.upfit-btn:hover,
.upfit-btn:active,
.upfit-btn:focus {
    color: #fff;
    background-color: #007C7C;
    border-color: #ccc;
}

.btn-primary-1:hover,
.btn-primary-1.hover {
    background-color: #007C7C;
}

.btn-primary-1:active,
.btn-primary-1.active {
    color: #fff;
    background-color: #007C7C;
    border-color: #adadad;
}

.btn-primary-1:active:hover,
.btn-primary-1.active:hover,
.btn-primary-1:active:focus,
.btn-primary-1.active:focus,
.btn-primary-1:active.focus,
.btn-primary-1.active.focus {
    color: #fff;
    background-color: #007C7C;
    border-color: #8c8c8c;
}

.btn-primary-1.active {
    color: #fff;
    background-color: #007C7C;
    border-color: #adadad;
}

.btn-primary-1:hover {
    color: #fff;
    background-color: #007C7C;
    border-color: #adadad;
}

.ui-paginator .ui-paginator-page.ui-state-active {
    background: #007c7c !important;
    border-color: #007c7c !important;
}

.turbo-charges-wrapper {
    min-height: 1024px !important;
}

@media(max-width: 640px) {
    .ui-grid-responsive .ui-grid-row {
        display: flex !important;
    }
}

.ui-grid-responsive .label {
    color: #333 !important;
    text-align: left !important;
    font-size: 100% !important;
}

@media (max-width: 600px) {
    .mat-tab-label {
        min-width: auto !important;
    }
}

.ui-widget-content a {
    cursor: pointer;
}

.ui-widget-content p>a {
    color: #23527c;
}

#navbar .nav li ul li ul li>a {
    padding-left: 3.5em;
}

.ui-datatable .ui-datatable-thead>tr>th,
.ui-datatable .ui-datatable-tfoot>tr>td,
.ui-datatable .ui-datatable-data>tr>td {
    text-align: left;
}

@media print {
    .well,
    .next_btn,
    .footer,
    header,
    .breadcrumb,
    .input-group-btn-vertical,
    .cartThumbnail,
    .not-print {
        display: none !important;
    }
    .input-group .form-control {
        border: none;
    }
    .be-printed {
        display: block !important;
    }
    #cart_search {
        display: none !important;
    }
    /* .ui-datatable-reflow .ui-datatable-data>tr>td:last-child {
        display: none !important;
    } */
    /* .ui-datatable .ui-datatable-thead>tr>th:last-child {
        display: none !important;
    }
    .ui-datatable .ui-datatable-data>tr>td:last-child {
        display: none !important;
    } */
    .print_img {
        display: block !important;
    }
    .hidden-column {
        display: none !important;
    }
    .visibility-column {
        visibility: hidden;
    }
    a[href]:after {
        content: none !important;
    }
    .table>thead>tr>th {
        text-align: left !important;
    }
    .qty-table-col-print {
        width: 12% !important;
        text-align: left !important;
    }
    .ui-treetable tbody td a {
        color: #333 !important;
    }
    .fa-caret-down:before {
        content: none !important;
    }
    .glyphicon-minus,
    .glyphicon-plus {
        display: none !important;
    }
}

.advance-search li>a {
    cursor: pointer;
}

.advance-search .form-group {
    /*    margin: 5px 20px 5px -15px;*/
    margin: 0px 0px 5px 5px;
}

.ui-widget-header-mobile {
    display: inline-block;
    padding: 4px 10px;
    border-bottom: 0 none;
    float: left;
}


/*
* Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
*
* [1] IE9
* [2] IE10+
*/


/* 1 */

object,
svg {
    cursor: pointer;
}

.ie9 img[src$=".svg"] {
    width: 100%;
}


/* 2 */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    img[src$=".svg"] {
        width: 100%;
    }
}

.svg-ie {
    margin: auto;
    border: 0 !important;
    width: 100%;
    height: 40vw;
}

@media only screen and (min-width: 767px) and (max-width: 960px) {
    .svg-ie {
        height: 30vw;
        margin-bottom: -35px;
    }
    .sidebar-collapse .svg-ie {
        height: 60vw;
        margin-bottom: -35px;
    }
}

@media only screen and (max-width: 767px) {
    .svg-ie {
        height: 60vw;
        margin-bottom: -35px;
    }
}

@media only screen and (max-width: 320px) {
    .svg-ie {
        height: 50vw !important;
    }
}

@media only screen and (min-width: 967px) and (max-width: 1080px) {
    .svg-ie {
        height: 25vw;
    }
    .sidebar-collapse .svg-ie {
        height: 40vw;
    }
}

@media only screen and (min-width: 1080px) and (max-width: 1560px) {
    .svg-ie {
        height: 32vw;
    }
    .sidebar-collapse .svg-ie {
        height: 40vw;
    }
}

.picklist-icon {
    background-color: white;
    color: black;
    margin-top: -5px;
}

.system-graphic1>a,
.system-graphic2>a {
    display: inline-block;
}

.system-graphic1 img {
    height: 225px !important;
    width: 280px;
    cursor: pointer;
}

.system-graphic2 img {
    cursor: pointer;
}

.system-graphic1 h3,
.system-graphic2 h3 {
    font-size: 12px;
}

.bg-color {
    background-color: #fcf8e3!important;
    color: #333!important;
    margin-bottom: 0;
}

.bg-color a {
    color: #333!important
}

label.ui-radiobutton-label {
    font-weight: 100 !important;
}

.data-table-cart-mobile>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(1) {
    background: #ebedf0;
    width: 100% !important;
}

.data-table-cart-mobile>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(5) {
    display: flex !important;
}

.cart-div {
    height: 10px;
    position: absolute;
    margin-top: -20px;
}

.cart-input {
    width: 50% !important;
}


/* .holset-parts-container>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(2),
.holset-parts-container>.ui-datatable-tablewrapper>table>tbody>tr>td:nth-child(5) {
    word-wrap: break-word !important;
}

.holset-parts-container>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(2),
.holset-parts-container>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(5),
.holset-parts-container>.ui-datatable-tablewrapper>table>thead>tr>th:nth-child(6) {
    width: 11% !important;
} */

.icon-prev,
.icon-next {
    background: #333 !important;
    height: 35px !important;
    cursor: pointer;
}

.carousel-indicators>li {
    background: black !important;
    cursor: pointer;
}

.center-block-div {
    margin: 0 auto;
    float: none;
}

.distributor-used .form-padding {
    padding: 0px 0px 0px 30px;
}

.compare-asssembly .form-padding {
    padding: 20px 20px 0px 20px;
}

.distributor-used .distributor-used-text {
    padding: 15px;
}


/*.mat-tab-labels {
    display: initial !important;;
}*/

.compare-bom-data .ui-datatable-scrollable-table-wrapper>table>tbody>tr>td:nth-child(2) {
    /*    color: green;
        font-weight: bold;*/
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
    flex-basis: 0;
}

.example-headers-align .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
}

.table-fixed thead {
    width: 97%;
}

.table-fixed tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
    border-bottom-width: 0;
}

.mat-expansion-panel-body {
    overflow: hidden !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #d02323 !important;
}

.match-unmatch-container>li>button {
    cursor: default !important;
}

.ui-treetable tbody .ui-treetable-row td {
    text-transform: uppercase;
}

.ui-treetable thead th,
.ui-treetable tbody td,
.ui-treetable tfoot td {
    overflow: visible !important;
    white-space: normal !important;
}

.zoom:hover {
    -webkit-transform: scale(4.5);
    /* Safari and Chrome */
    -moz-transform: scale(4.5);
    /* Firefox */
    -ms-transform: scale(4.5);
    /* IE 9 */
    -o-transform: scale(4.5);
    /* Opera */
    transform: scale(4.5);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    border: 1px solid #333;
    cursor: pointer;
    width: 75px;
    margin-top: 3em;
    overflow: visible !important;
    /*    position: absolute;*/
}

legend {
    text-align: left !important;
    width: auto !important;
    font-size: initial !important;
    margin-bottom: 10px;
}

.ui-fieldset-content-wrapper {
    text-align: left !important;
}

fieldset {
    margin-bottom: 15px;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span {
    width: 300px !important;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right),
.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
    width: 300px !important;
    background: #eee !important;
    color: #333 !important;
}

.swiper-wrapper {
    box-sizing: border-box;
}

h1 h2 {
    font-weight: 800 !important;
}

.ui-radiobutton-icon {
    display: block;
    font-size: .5em;
    line-height: inherit;
    margin-top: -1px;
}

.sidebar-collapse .main-sidebar,
.sidebar-collapse .left-side {
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    -o-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}

.background-updated-row {
    background: #c0c0c0 !important;
    border: 1px solid #d5d5d5 !important;
}

.popover-img-1 {
    width: 100px !important;
    cursor: pointer !important;
}

.vert-offset-top-0-5 {
    margin-top: 0.5em;
}

.notification-popup>div:nth-child(2)>p {
    font-size: 13pt;
    color: rgb(0, 0, 0, 0.65);
}

.notification-popup>div:nth-child(2)>div {
    border-top: 1px solid #f4f4f4;
    padding-top: 10px;
}

.icon-custom {
    color: #7c7cb7;
    font-size: 16pt;
}

@media (max-width: 768px) {
    p-dialog>>>.notification-popup {
        display: none !important;
    }
    .ui-widget-overlay {
        z-index: -999 !important;
        background: none !important;
    }
}

.holset-parts-container>.ui-datatable-tablewrapper>table {
    table-layout: inherit !important;
}

.m-left {
    margin-left: 1em;
}

.mr-0-5 {
    margin-left: 0.5em !important;
}

.custom-alert.alert-info {
    color: #383d41 !important;
    background-color: #e2e3e5 !important;
    border-color: #d6d8db !important;
}

.custom-alert.alert-warning {
    color: #856404 !important;
    background-color: #ffeeba !important;
    border-color: #ffeeba !important;
}

.custom-alert::before {
    top: 1px;
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-family: 'Glyphicons Halflings', sans-serif;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.custom-alert.alert-info::before {
    content: '\e086';
}

.custom-alert.alert-warning::before {
    content: '\e209';
}

.current-esn {
    border: 1px solid #cecece
}

@media all and (min-width: 768px) and (max-width: 992px) {
    .content-wrapper.custom .content .search-content {
        margin-left: 175px !important;
        margin-right: 75px;
    }
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: -5px !important;
}

@media screen {
    .print-only {
        display: none !important;
    }
    .be-printed {
        display: none !important;
    }
}

.treeUL ul {
    list-style-type: none;
}

.treeUL {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.treeUL .collapsible-item-body ul {
    list-style-type: disc !important;
}

.c-bullet .c-bullet-down {
    cursor: pointer;
    border: none;
    text-align: left;
    outline: none;
}

.c-bullet::before {
    cursor: pointer;
    content: '\002B';
    padding: 1px 3px 1px 3px;
    font-weight: bold;
    font-size: 10px;
    background-color: gray;
    color: white;
    margin: 0px 5px;
}

li div.c-bullet:nth-child(1){
    cursor: pointer;
} 

li div.c-bullet-down:nth-child(1){
    cursor: pointer;
} 

li div .c-bullet .c-bullet-down{
    margin: 10px;
}

.c-bullet-down::before {
    cursor: pointer;
    content: "\2212";
    padding: 1px 3px 1px 3px;
    font-weight: bold;
    font-size: 10px;
    background-color: gray;
    color: white;
    margin: 0px 5px;
}

.c-nested {
    display: none;
}

.c-active {
    display: block;
}

#preview section>.collapsible-item>.collapsible-item-heading {
    background: #fff !important;
    color: #000;
}

.collapsible-item-title-link-icon>.glyphicon-chevron-down:before {
    content: none !important;
}

.loader {
    border: 5px solid #f3f3f3;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 5px;
    height: 5px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
