/*****CMI-header-component******/


/*
Global Header Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/

.top {
    background-color: #000;
    padding: 0px 0 7px;
    height: 35px;
}

.top .container {
    padding-right: 15px;
}

#global-buttons {
    padding-right: 5px;
}

.global-container {
    max-width: 1024px;
}

.navbar-right .global-btn {
    border: 0px;
    background: none;
    padding: 6px 0;
    border-radius: 0;
}

.navbar-right .global-btn:hover,
.navbar-right .global-btn:active {
    background: #333;
}

.navbar-right .global-btn:hover a .icon-globe,
.navbar-right .open .global-btn a .icon-globe {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -70px -1940px rgba(0, 0, 0, 0) !important;
}

.navbar-right .global-btn:hover a .icon-phone {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -70px -1100px rgba(0, 0, 0, 0) !important;
}

.navbar-right .global-btn:hover a .icon-community,
.navbar-right .open .global-btn a .icon-community {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -70px -2571px rgba(0, 0, 0, 0) !important;
}

.navbar-right .open {
    background: #333;
}

.navbar-right .global-btn a {
    color: #fff;
    padding: 10px 10px;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 10px;
}

.navbar-right .global-btn .icon-phone {
    color: #999999;
    padding: 10px 10px 10px 0px;
    font-size: 16px;
    text-transform: uppercase;
    vertical-align: middle;
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -1100px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}

.navbar-right .global-btn .icon-training {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -3320px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}

.navbar-right .global-btn .icon-globe {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -1940px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}

.navbar-right .global-btn .icon-community {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -2571px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}


/*************************************/

.navbar-right .global-btn .icon-locator {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -2900px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}

.navbar-right .global-btn:hover a .icon-locator,
.navbar-right .open .global-btn a .icon-locator {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -70px -2900px rgba(0, 0, 0, 0) !important;
}

.navbar-right .global-btn .icon-help {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -3020px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}

.navbar-right .global-btn:hover a .icon-help,
.navbar-right .open .global-btn a .icon-help {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -70px -3020px rgba(0, 0, 0, 0) !important;
}


/*.icon-cart {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -40px -2450px rgba(0, 0, 0, 0);
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: right;
    width: 22px;
}

.icon-cart,
.icon-cart {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -60px -2450px rgba(0, 0, 0, 0) !important;
}*/

.icon-home {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -60px -2450px rgba(0, 0, 0, 0) !important;
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: left;
    width: 22px;
}

.icon-information {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -10px -2750px rgba(0, 0, 0, 0) !important;
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: right;
    width: 22px;
}

.icon-compare {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -10px -1550px rgba(0, 0, 0, 0) !important;
    display: inline-block;
    height: 20px;
    margin: 1px 0px 10px 10px;
    position: relative;
    float: right;
    width: 22px;
}

/*************************************/

.navbar-right .phone-btn,
.navbar-right .globe-btn,
.navbar-right .community-btn {
    height: 35px;
    padding-right: 10px;
}

.navbar-right .dropdown-menu {
    min-width: 110px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.navbar-right .dropdown-menu li {
    background-color: #fff;
    border-bottom: solid 1px #ccc;
}

.navbar-right .dropdown-menu li:hover {
    background-color: #007c7c;
}

.navbar-right .dropdown-menu li a {
    padding: 10px;
    text-align: left;
    color: #666;
    font-size: 13px/15px;
}

.navbar-right .dropdown-menu li a:hover {
    color: #fff;
    background-color: #007c7c;
}

.navbar-right .dropdown-menu li:last-child {
    border-bottom: solid 2px #666;
}

.navbar-right .btn-group .dropdown-menu {
    right: 0;
    left: inherit;
}

@media only screen and (max-width: 750px) {
    .navbar-right {
        float: right !important;
    }
    .navbar-right .global-btn .icon-community,
    .navbar-right .global-btn .icon-globe,
    .navbar-right .global-btn .icon-phone
    .navbar-right .global-btn .icon-training {
        padding: 10px 0px;
    }
    .navbar-right .community-btn {
        padding-right: 0px;
    }
    .navbar-right .phone-btn a,
    .navbar-right .globe-btn a,
    .navbar-right .community-btn a {
        padding-right: 0px;
    }
    .navbar-right .phone-btn a,
    .navbar-right .globe-btn a {
        padding-left: 0px;
    }
}


/*****CMI-banner-carousel-component******/


/*
Banner Carousel Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/

.banner-carousel-component {
    background-color: #000000;
    position: relative;
}

.banner-carousel-component .container {
    padding-right: 0px;
    padding-left: 0px;
}

.bc-slider {
    list-style: none;
    position: relative;
    width: 1024px;
    height: 365px;
    margin: 0 auto;
    padding: 0;
    border-bottom: 3px solid #D02323;
}

.bc-slider img {
    max-width: 100%;
}

.bc-slider li {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
}


/* Timebar */

.bc-slider_timer {
    background-color: #d02323;
    width: 0%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    /*opacity: 0.5;
    -moz-box-shadow: 0px 2px 2px black;
    -webkit-box-shadow: 0px 2px 2px black;
    -o-box-shadow: 0px 2px 2px black;
    box-shadow: 0px 2px 2px black;*/
}


/* Progress bar preloader container */

.bc-slider_preloader {
    background: rgba(255, 255, 255, 0.5);
}


/* progress bar preloader */

.bc-slider_preloader .bc-slider_progress {
    width: 30%;
    height: 5px;
    margin: 0 auto;
    border-top: 1px solid #7c7c7c;
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #949494;
    border-right: 1px solid #949494;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #9a9a9a), color-stop(100%, #b3b3b3));
    background: -webkit-linear-gradient(#9a9a9a, #b3b3b3);
    background: -moz-linear-gradient(#9a9a9a, #b3b3b3);
    background: -o-linear-gradient(#9a9a9a, #b3b3b3);
    background: -ms-linear-gradient(#9a9a9a, #b3b3b3);
    background: linear-gradient(#9a9a9a, #b3b3b3);
}


/* progress bar fill */

.bc-slider_preloader .bc-slider_progress span {
    display: block;
    height: 100%;
    background: #66cccc;
}

.bc-slider_layers {
    position: absolute;
    width: 60%;
    height: 100%;
    overflow: hidden;
    display: table;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.slide_bottom {
    display: table-cell !important;
    vertical-align: middle;
    padding-left: 115px;
}

.slide_bottom h1 {
    font-weight: 900;
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 0px;
}

.slide_bottom h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 30px !important;
    /*10px offset fix actual margin equals 20px */
}


/* Hand cursor over navigation elements */

.bc-slider_navigation {
    cursor: pointer;
}


/* Tooltip panel */

.bc-slider_caption {
    position: absolute;
    bottom: 10px;
    left: 0;
    text-shadow: black 0 1px 0;
    padding: 10px 0px;
    width: 100%;
    background-color: #373737;
    border-top: 1px solid #545454;
    border-bottom: 1px solid #545454;
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    color: white;
    text-align: center;
    font-size: 13px;
}


/* play, pause, previous and next elements */

.bc-slider_pause,
.bc-slider_play,
.bc-slider_left,
.bc-slider_right {
    text-indent: -119988px;
    overflow: hidden;
    text-align: left;
    position: absolute;
    display: block;
    border-radius: 50% !important;
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll #e6e6e6;
}


/* previous and next elements hover state */

.bc-slider_left:hover {
    background-color: white;
}

.bc-slider_right:hover {
    background-color: white;
}


/* previous and next elements */

.bc-slider_left,
.bc-slider_right {
    width: 35px;
    height: 35px;
    margin-top: -32px;
    top: 50%;
}


/* previous */

.bc-slider_left {
    background-position: -1px -283px;
    left: 15px;
    -ms-transform: rotate(180deg);
    /* IE 9 */
    -webkit-transform: rotate(180deg);
    /* Chrome, Safari, Opera */
    transform: rotate(180deg);
}


/* next */

.bc-slider_right,
.bc-slider_right:hover {
    background-position: -1px -283px;
    right: 15px;
}


/* bullet indicator */

.bc-slider_indicator {
    position: absolute;
    bottom: 7px;
    left: 0;
    width: 100%;
    text-align: center;
    _text-align: right;
    *text-align: right;
}

.bc-slider_indicator a {
    text-indent: -119988px;
    overflow: hidden;
    text-align: left;
    -moz-box-orient: vertical;
    vertical-align: middle;
    *vertical-align: auto;
    cursor: pointer;
    margin: 3px;
    *display: inline;
    background: none repeat scroll 0 0 transparent;
    border: 2px solid #fff;
    height: 12px;
    width: 12px;
    border-radius: 50% !important;
    margin-right: 15px;
}

.bc-slider_indicator a:hover {
    background: none repeat scroll 0 0 #D02323;
    border-radius: 50% !important;
    cursor: pointer;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 3px;
    border: none;
    position: relative;
    margin-right: 15px;
}

.bc-slider_indicator_active:after {
    background: none repeat scroll 0 0 #fff;
    border-radius: 50% !important;
    content: "";
    height: 8px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 8px;
}

.bc-slider_indicator a.bc-slider_indicator_active {
    background: none repeat scroll 0 0 #fff;
    border-radius: 50% !important;
    cursor: pointer;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 3px;
    position: relative;
    margin-right: 15px;
}

@media all and (max-width: 800px) {
    .slide_bottom {
        position: relative !important;
        vertical-align: middle;
    }
    .slide_bottom h1 {
        font-weight: 900;
        font-size: 3.9vw;
        line-height: 3.9vw;
        text-transform: uppercase;
        color: #fff;
        margin-top: 0px;
    }
    .slide_bottom h2 {
        font-weight: 300;
        font-size: 2.9vw;
        line-height: 2.9vw;
        color: #fff;
        margin-bottom: 9% !important;
        margin-top: 0px !important;
    }
    .slide_bottom .special-action-btn {
        font-size: 15px;
        /*font-size:1.9vw;*/
        padding: 3% 2%;
        height: 35px;
    }
    .banner-close {
        display: none;
    }
}

@media all and (max-width: 320px) {
    .breadcrumb-component .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .slide_bottom {
        padding-left: 65px;
        top: 5%;
    }
    .slide_bottom h1 {
        font-weight: 900;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #fff;
        margin-top: 0px;
    }
    .slide_bottom h2 {
        font-weight: 300;
        font-size: 12px;
        line-height: 13px;
        color: #fff;
        margin-bottom: 10px !important;
        margin-top: 10px;
        width: 215px;
    }
    .slide_bottom .special-action-btn {
        font-size: 10px;
        padding: 5px 5px;
        height: 35px;
    }
    .bc-slider_indicator,
    .bc-slider_left,
    .bc-slider_right {
        display: none;
    }
    .banner-close {
        display: block;
        position: absolute;
        left: 90%;
        top: 2%;
        z-index: 99999;
    }
}


/*****CMI-banner-carousel-component******/


/*****CMI-banner-navigation-component******/


/*
Banner Navigation Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/

body {
    background: none repeat scroll 0 0 #fff;
    margin: 0;
    padding: 0;
}

.header {
    font-size: 13px;
    filter: progid: DXImageTransform.Microsoft.Gradient(startColorstr='#dc2028', endColorstr='#af0d1f');
    background: linear-gradient(to top, #dc2028, #af0d1f);
}

.navbar-header {
    float: none;
    max-height: 40px;
}

.logo {
    display: inline-block;
    padding-left: 0px;
    padding-right: 0px;
}

.logo a:hover .sub-brand h2 {
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.35);
}

.logo a:hover .logo_img img {
    -webkit-filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.35));
    filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.35));
}

.logo_img {
    display: inline-block;
    padding: 12px 80px 12px 10px;
    float: left;
}

.sub-brand h2 {
    color: #fff;
    margin: 0;
    font-size: 24px;
    line-height: 23px;
}

.sub-brand {
    padding: 12px 0px 0px 0px;
    width: 140px;
    display: inline-block;
}

.main_menu {
    display: inline-block;
    position: relative;
    /*    right: -25%;*/
    right: -40%;
    bottom: 28px;
    z-index: 99;
    /*added Css*/
    top: 0em;
}

.main_menu>ul {
    margin-bottom: 0;
    margin-left: 0;
}

#nav {
    display: inline-block;
}

#nav li {
    float: left;
    list-style: outside none none;
    position: relative;
}

#nav>li>a {
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

#nav>li:hover>a {
    background: none repeat scroll 0 0 #840909;
}

#nav>li:last-child {
    border: 0px;
}

#nav img {
    width: 11px;
}

#nav li>ul {
    background: none repeat scroll 0 0 #fff;
    box-shadow: 6px 6px 5px -4px #666;
    display: none;
    padding: 0;
    position: absolute;
    top: 27px;
}

#nav li:hover ul {
    display: block;
}

#nav li ul li {
    border: 1px solid #cccccc;
}

#nav li ul li a {
    color: #333;
    display: table;
    padding: 10px 26px 10px 10px;
    text-decoration: none;
    width: 165px;
}

#nav li ul li:hover a {
    background: #007C7C;
    color: #fff;
}

#nav .divider {
    border-right: 1px solid #e48f92;
    height: 20px;
}

#nav .divider.border-hidden {
    border-right: 1px solid transparent;
}

#nav li a span.caret1 {
    display: none;
    position: absolute;
    left: 5px;
    width: 10px;
    height: 16px;
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -35px -1791px;
    background-size: 125px 2130px;
}

#nav li a span.caret1 img {
    width: 7px;
}

#nav li a span.banner-caret-down {
    display: inline !Important;
}

.nav-active {
    background-color: #840909;
}

#nav li.no-right-border {
    border-right: 1px solid transparent;
}


/** Search Styles **/

.search-container,
.responsive-search-container {
    width: 40px;
    height: 35px;
    float: right;
}

.search-btn {
    position: relative;
    top: -7px;
    right: 27px;
}

.search-container .search,
.responsive-search-container .search {
    background: #840909;
    color: #fff;
    cursor: pointer;
    line-height: 35px;
    padding: 8px 14px 10px;
    position: absolute;
    text-decoration: none;
    bottom: 16px;
    height: 35px;
    width: 40px;
    display: inline-block;
    margin-bottom: -8px;
}

#searchSite .results {
    display: none;
    list-style-type: none;
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    border-width: 1px 1px 2px;
    border-style: solid;
    border-color: #cccccc #cccccc #666;
    border-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    -ms-box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    -o-box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
}

#searchSite .results li {
    border-bottom: 1px solid #cccccc;
    margin: 0px;
    padding: 0px;
    width: 100%;
}

#searchSite .results li:hover,
#searchSite .results li:hover a {
    background-color: #007c7c;
    color: #fff;
}

#searchSite .results li a {
    color: #333;
    text-decoration: none;
    font-size: 13px;
    display: block;
    line-height: 15px;
    width: 100%;
    height: 100%;
    padding: 10px;
}

#searchSite .results li a:hover {
    color: #fff;
}

.search_icon {
    background: none repeat scroll 0 0 #840909;
    color: #fff;
    cursor: pointer;
    display: table;
    padding: 7px 13px;
    right: 54px;
    top: -6px;
}

.search-open {
    right: 0;
    top: 27px;
    display: none;
    padding: 0;
    position: absolute;
    border-top: 0;
    z-index: 99999;
    zoom: 1;
}

.search-open form {
    background-color: #840909;
    padding: 4px;
    border-radius: 3px !important;
}

.search-open input {
    background-color: #fff;
    border: 0;
    border-radius: 3px !important;
    color: #000000;
    margin: 0;
}

.icon-search {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -63px -2145px;
    display: inline-block;
    height: 34px;
    margin: 0px 0px 10px 10px;
    position: relative;
    float: left;
    width: 35px;
}

.searchicon input {
    padding-left: 10px;
    padding-right: 25px;
    padding-top: 3px;
}

.searchicon:before {
    right: 12px;
    top: 6px;
    content: "";
    width: 15px;
    height: 16px;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    position: absolute;
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -25px -1438px;
    background-size: 167px 2854px;
    background-repeat: no-repeat;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
}

.icon-remove:before {
    content: "";
}

.icon-remove {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -68px -48px;
    display: inline-block;
    height: 25px;
    float: left;
    width: 25px;
    position: absolute;
    top: auto !important;
    left: auto !important;
    bottom: 5px;
    right: 7px!important;
}


/** Mobile responsive menu style **/


/** Responsive Menu CSS **/

.rmm ul li ul {
    background: #fff;
    display: none !important;
}

.rmm ul li.open ul {
    display: block !important;
}

.rmm li ul li.open a {
    background: #007C7C;
    color: #fff;
}


/* END TJG MODIFIED */

.rmm li ul li a {
    color: #333;
    display: table;
    padding: 10px 26px;
    text-decoration: none;
    width: 165px;
}

.rmm li ul li:hover a {
    background: #007C7C;
    color: #fff;
}

.rmm li li:last-child {
    border-bottom: 2px solid #666;
}

.rmm {
    display: block;
    line-height: 19px !important;
    margin: auto auto auto 341px;
    padding: 0;
    text-align: center;
    width: 165px;
    position: absolute;
    top: 0;
}

.rmm * {
    -webkit-tap-highlight-color: transparent !important;
    font-weight: 500;
}

.rmm a {
    color: #ebebeb;
    text-decoration: none;
}

.rmm .rmm-main-list,
.rmm .rmm-main-list li {
    margin: 0px;
    padding: 0px;
}

.rmm ul {
    display: block;
    width: 166px !important;
    /*width:auto!important;*/
    margin: 0 auto !important;
    overflow: hidden;
    list-style: none;
    background: none repeat scroll 0 0 #d02323;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}

.box-shadow {
    position: absolute;
    margin: 100px;
    -webkit-box-shadow: 6px 6px 5px -4px #666;
    -moz-box-shadow: 6px 6px 5px -4px #666;
    box-shadow: 6px 6px 5px -4px #666;
}

.rmm .rmm-main-list li {
    display: inline;
    padding: 0px;
    margin: 0px !important;
}

.rmm-toggled {
    display: none;
    overflow: hidden;
    margin: 0 auto !important;
}

.rmm-button:hover {
    cursor: pointer;
}

.rmm .rmm-toggled ul {
    display: none;
    margin: 0px !important;
    padding: 0px !important;
}

.rmm .rmm-toggled ul li {
    display: block;
}

.rmm .rmm-toggled ul li ul li a:hover {
    display: block;
    background-color: #007c7c;
    color: #fff;
}


/** Minimal Style **/

.rmm.minimal a {
    color: #333;
}

.rmm.minimal a:hover {
    background-color: #840909;
}

.rmm.minimal .rmm-main-list li a {
    display: inline-block;
    padding: 8px 30px 8px 30px;
    margin: 0px -3px 0px -3px;
    font-size: 15px;
}

.rmm.minimal .rmm-toggled {
    min-height: 36px;
}

.rmm.minimal .rmm-toggled-controls {
    display: block;
    height: 36px;
    color: #333;
    text-align: left;
}

.rmm.minimal .rmm-toggled-title {
    position: relative;
    top: 9px;
    left: 9px;
    font-size: 16px;
    color: #333333;
}

.rmm.minimal .rmm-button {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -60px -2203px #840909;
    display: block;
    padding: 6px 8px;
    position: absolute;
}

.rmm.minimal .rmm-button span {
    display: block;
    margin: 4px 0px 4px 0px;
    height: 2px;
    background: #fff;
    width: 25px;
}

.rmm.minimal .rmm-toggled ul li a {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 10px 10px 10px;
    border-bottom: 2px solid #666;
}

.sub-cat li a {
    padding: 10px 20px !important;
}


/* Media Queries */

@media all and (max-width: 1027px) {
    .main_menu {
        /*        margin-left: -31px;*/
        /*        top: 47px;*/
        right: -25%;
    }
    #nav>li>a {
        margin-left: 0;
        margin-right: 0;
    }
}

@media all and (min-width: 1024px) {
    .mobile_menu {
        display: none;
    }
    .rmm {
        display: none !important;
    }
}

@media all and (max-width: 1023px) {
    .main_menu #nav {
        display: none;
    }
    .search_icon {
        left: 665px;
        top: 51px;
        position: absolute;
    }
    .search-open {
        margin-right: 0px;
    }
    .responsive-search-container {
        position: absolute;
        top: 82px;
        right: 20px;
        display: block !important;
    }
    .search-btn {
        top: -6px;
        right: 22px;
    }
    #nav li {
        border-right: 1px solid #fdf6f6;
        float: none;
        list-style: outside none none;
        position: relative;
    }
    .rmm {
        display: block !important;
    }
    .rmm.minimal .rmm-toggled-controls {
        height: 74px !important;
    }
    .rmm-button {
        top: 39px;
        right: 0;
        width: 40px;
        height: 35px;
    }
    .has-subnav .sub-cat {
        background: none repeat scroll 0 0 #fff;
    }
    .minimal {
        position: absolute;
        right: 70px;
        z-index: 99;
    }
    .search-mobile {
        position: absolute;
    }
    .rmm.minimal .rmm-toggled-title {
        display: none;
    }
    .rmm.minimal .rmm-toggled ul>li>a {
        color: #fff;
    }
    .rmm.minimal .rmm-toggled ul ul li a {
        color: #666;
    }
}

@media all and (max-width: 750px) {
    .navbar-header {
        max-height: 75px;
    }
}

@media all and (max-width: 727px) {
    .search_icon {
        left: 574px !important;
    }
}

@media all and (max-width: 620px) {
    .rmm {
        margin: auto auto auto 88px !important;
    }
    .search_icon {
        left: 432px !important;
    }
}

@media all and (max-width: 473px) {
    .rmm {
        margin: auto auto auto 67px !important;
    }
    .search_icon {
        left: 358px !important;
    }
}

@media all and (max-width: 400px) {
    .sub-brand {
        width: 135px;
    }
    .rmm-button {
        top: 39px;
        right: 0;
        width: 40px;
        height: 35px;
    }
    .minimal {
        position: absolute;
        right: 70px;
    }
    .search-container {
        position: absolute;
        top: 47px;
        right: 20px;
    }
    .logo_img {
        display: inline-block;
        padding: 12px 20px 12px 15px;
        float: left;
    }
    .logo_text {
        width: 139px !important;
    }
    .search_icon {
        left: 269px !important;
    }
}

@media (min-width: 971px) and (max-width: 992px) {
    #nav>li>a:not(.search) {
        padding-left: 0.44em;
        padding-right: 0.44em;
        font-size: 1em;
    }
}

@media (min-width: 801px) and (max-width: 970px) {
    #nav {
        min-width: 495px;
    }
    .main_menu {
        padding-left: 0px;
        padding-right: 0px;
    }
    #nav>li>a:not(.search) {
        padding-left: 0.25em;
        padding-right: 0.25em;
        font-size: 1em;
    }
}

@media (max-width: 320px) {
    .logo_img {
        padding-left: 0px;
        padding-right: 5px;
    }
    .sub-brand {
        width: 115px;
    }
    .sub-brand h2 {
        font-size: 17px;
        line-height: 19px;
    }
}


/*****CMI-banner-navigation-component******/


/*****CMI-global-footer-component******/


/*
Global Footer Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/

.global-footer-logo {
    padding: 10px 25px 10px 15px;
}

.global-footer-copy {
    font-weight: 300;
    font-size: 13px;
    color: #cccccc;
    line-height: 15px;
    position: absolute;
    bottom: 7px;
    padding-left: 0px;
}

.global-footer-links {
    font-weight: 300;
    font-size: 10px;
    color: #cccccc;
    line-height: 20px;
    text-align: right;
    position: relative;
    top: 36px;
}

.global-footer-links a {
    color: #fff;
    text-transform: uppercase;
    padding: 0;
    margin: 0 10px;
}

.global-footer-links a:hover {
    border-bottom: 1px solid white;
}

.mobile-footer-links {
    font-weight: 300;
    font-size: 11px;
    color: #fff;
    line-height: 30px;
    text-transform: uppercase;
    padding-left: 50px;
    white-space: nowrap;
    width: 100% !important;
    padding-right: 0px;
}

.mobile-footer-links:hover {
    color: #fff;
}

.mobile-footer-copy {
    font-weight: 300;
    font-size: 13px;
    color: #cccccc;
    line-height: 15px;
    position: absolute;
    bottom: 7px;
}

.eprivacy {
    position: absolute;
    left: 0px;
    bottom: 0px;
    margin-left: -7px;
}

.ePrivacy-icon-mobile {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

@media only screen and (max-width: 320px) {
    .global-footer-logo {
        padding: 10px 10px 10px 5px;
    }
    .mobile-footer-links {
        padding-left: 40px;
    }
}

@media (min-width: 751px) and (max-width: 930px) {
    .global-footer-links a {
        padding: 7px;
        font-size: 1em;
    }
}


/*****CMI-global-footer-component******/


/*****CMI-fat-footer-component******/


/*
Fat Footer Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/

.large-padding div:last-child {
    padding-right: 20px;
}

.large-padding div:first-child {
    /*    --padding-left: 30px;*/
    padding-bottom: 15px;
}

.ff-column {
    padding-left: 10px;
    padding-right: 10px;
}

.fat-footer h4 {
    font-weight: 700;
    border-bottom: 2px solid #fff;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
    padding: 60px 0 10px 0;
    color: #fff;
    /* white-space: nowrap; */
}

.fat-footer a {
    display: block;
}

.fat-footer a:hover {
    color: #fff;
}

.fat-footer .dl-horizontal {
    list-style-type: none;
    margin-bottom: 0px;
    padding: 20px 10px;
    /*    added line*/
}

.fat-footer .dl-horizontal li:nth-child(1) {
    font-weight: 900;
    font-size: 15px;
}

.fat-footer .dl-horizontal li {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    border-bottom: 1px solid #fff;
}

.fat-footer .dl-horizontal li:hover,
.fat-footer .dl-horizontal a:hover {
    background-color: #333;
    text-decoration: none;
}

.fat-footer .dl-horizontal li a {
    color: #fff;
    text-decoration: none;
    padding: 10px 10px;
    line-height: 15px;
}


/** Mobile Footer Styling **/

.fat-footer .panel-group .panel-default {
    border: none;
    box-shadow: none;
}

.fat-footer .panel-group .panel {
    background-color: #000000;
    border: none;
    box-shadow: none;
    margin-top: 0px;
}

.fat-footer .panel-group .panel .panel-collapse .panel-body {
    padding: 0px 20px;
    border: none !important;
}

.fat-footer .panel-group .panel .panel-heading {
    background-color: #000000;
    border: none !important;
    padding: 0px 0px;
}

.fat-footer .panel-group .panel .panel-heading .panel-title:hover {
    background-color: #333;
}

.fat-footer .panel-group .panel .panel-heading h4 {
    padding: 10px 0px;
}

.fat-footer .panel-group .panel .panel-heading .panel-title a:hover {
    text-decoration: none;
}

.panel-heading .accordion-toggle:focus {
    /*remove underline in IE*/
    text-decoration: none;
}

.panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    content: "";
    /*adjust as needed, taken from bootstrap.css */
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll;
    background-size: 167px 2854px;
    background-repeat: no-repeat;
    float: left;
    /* adjust as needed */
    padding-right: 25px;
    width: 15px;
    height: 17px;
    background-position: -45px -192px;
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(90deg);
    margin-top: 5px;
}

.panel-heading .accordion-toggle.collapsed:after {
    background-position: -45px -192px;
    -ms-transform: rotate(0deg);
    /* IE 9 */
    -webkit-transform: rotate(0deg);
    /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    margin-top: 0;
}

@media only screen and (max-width: 750px) {
    .fat-footer .dl-horizontal li a {
        color: #fff;
        text-decoration: none;
        display: block;
        width: 100%;
        /*        height: 100%;*/
        padding: 10px 10px 10px 25px;
    }
}

@media only screen and (max-width: 800px) {
    .fat-footer a:hover {
        text-decoration: none;
    }
    .fat-footer {
        padding-top: 30px;
    }
    .fat-footer #accordion {
        margin-left: 20px;
        margin-right: 20px;
    }
    .fat-footer h4 {
        padding: 50px 0 10px 0;
    }
    .panel-body {
        padding: 0px;
    }
    .fat-footer .panel-group .panel .panel-collapse .panel-body {
        padding: 0px 0px;
    }
}


/*****CMI-fat-footer-component******/


#contact-form .contact-form-main {
    padding-left: 30px;
    padding-right: 30px;
}

#contact-form .contact-form-info {
    padding-left: 30px;
    padding-right: 30px;
}

#contact-form h1 {
    border-bottom: 2px solid #006666;
    color: #000000;
    margin-top: 0;
}

#contact-form .dropdown .btn {
    padding: 3px 10px;
    border-radius: 3px;
    border-color: #cccccc;
    text-align: left;
    color: #666;
    font-size: 12px;
    /*    font-style: italic;*/
    font-weight: 400;
    line-height: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
}

#contact-form .dropdown .btn:focus {
    font-style: normal;
    box-shadow: 0 0 5px #007c7c;
}

#contact-form .dropdown .btn:hover,
#contact-form .dropdown .btn:active {
    background-color: #fff;
}

#contact-form .contact-form-left {
    margin-left: 0px;
}

#contact-form .contact-form-left .form-group,
#contact-form .contact-form-right .form-group {
    margin-bottom: 20px;
    padding: 0px;
}

#contact-form .contact-form-left,
#contact-form .contact-form-right,
#contact-form .contact-form-bottom {
    border: 1px solid #999999;
    background-clip: content-box;
    padding: 0px;
}

#contact-form .form-group div {
    margin-left: 0px;
    padding-left: 0px;
}

#contact-form .form-group label {
    top: 5px;
    margin-right: 0px;
    padding-right: 0px;
    text-align: right;
}

#contact-form .details {
    margin-bottom: 30px;
}

#contact-form .radio-title,
#contact-form .checkbox-title,
#contact-form .details label {
    font-size: 13px;
    line-height: 15px;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    white-space: nowrap;
    text-align: right;
}

#contact-form .radio-buttons .required,
#contact-form .checkboxes .required,
#contact-form .details .required {
    font-weight: 400;
    font-size: 13px;
    color: #D02323;
    padding-top: 10px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}

#contact-form .radio-buttons .radio-holder,
#contact-form .checkboxes .checkbox-holder {
    padding-left: 0px;
}

#contact-form .details .details-input {
    padding-left: 0px;
}

#contact-form .form-group .required {
    font-weight: 400;
    font-size: 14px;
    color: #D02323;
    padding-right: 0px;
    top: 5px;
}

#contact-form input {
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
    color: #333333;
}

#contact-form input[type="text"] {
    font-size: 12px;
    line-height: 15px;
    color: #333333;
}

button[aria-expanded="false"] {
    font-style: normal!important;
    color: #333333!important;
}

::-webkit-input-placeholder {
    color: #666;
    /*    font-style: italic;*/
}


/*Chrome */

:-moz-placeholder {
    color: #666;
    /*    font-style: italic;*/
}


/*ff*/

:-ms-input-placeholder {
    color: #666;
    /*    font-style: italic;*/
}


/*ie latest */

#contact-form input[type="text"]:focus {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    font-style: normal;
}

#contact-form .form-control:focus {
    box-shadow: 0 0 5px #007c7c;
}

#contact-form .dropdown .btn .caret {
    position: absolute;
    right: 23px;
    top: 12px;
}

#contact-form .dropdown button[aria-selected="false"] {
    font-style: normal!important;
}

#contact-form label {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #333333;
}

#contact-form option:hover {
    background-color: #007C7C;
    color: #fff;
}

#contact-form span.fieldset-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    background-color: #eeeeee;
    position: relative;
    left: 10px;
    bottom: 13px;
    padding: 5px;
}

label.radio-label,
label.checkbox-label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 13px;
}

.radio input[type=radio] {
    display: none;
}

label.radio-label:before,
label.checkbox-label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 2px;
    background-color: #fff;
    box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(255, 255, 255, .8);
}

label.radio-label:before {
    border-radius: 8px;
}

.radio input[type=radio]:checked+label.radio-label:before {
    content: "\2022";
    color: #007c7c;
    font-size: 30px;
    text-align: center;
    line-height: 18px;
}

#contact-form .radio-buttons li,
#contact-form .checkboxes li {
    display: inline-block;
}

#contact-form .radio-buttons label,
#contact-form .checkboxes label {
    line-height: 22px;
    margin-right: 20px;
}

.checkbox input[type=checkbox] {
    display: none;
}

.checkbox label:before {
    border-radius: 3px;
}

input[type=checkbox]:checked+label:before {
    background: url("/assets/icons/CMI-WebIcon-Sprite.png") no-repeat scroll -101px -4192px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 15px;
    color: #007c7c;
    text-align: center;
    line-height: 15px;
}

#contact-form .required-text {
    text-align: right;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}

#contact-form .required-text span {
    font-weight: 400;
    font-size: 14px;
    color: #D02323;
}

.contact-form-wrapper {
    background: #eeeeee;
    background-clip: content-box;
    padding-left: 30px;
    padding-right: 30px;
}

.contact-form-left-wrap {
    padding-right: 10px;
    padding-bottom: 10px;
}

.contact-form-right-wrap {
    padding-left: 10px;
    padding-bottom: 10px;
}

.contact-form-bottom-wrap {
    padding-top: 10px;
}

.contact-form-left-wrap,
.contact-form-right-wrap,
.contact-form-bottom-wrap {
    margin-top: 20px;
}

.contact-form-bottom-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
}

.primary-cta {
    background: #666;
    background-image: linear-gradient(to bottom, #666, #000000);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    margin: 0px 10px;
}

.primary-cta:hover,
.primary-cta:focus {
    background: #333333;
    text-decoration: none;
    color: #fff;
}

.primary-cta:first-child {
    margin-left: 0px;
}

.secondary-cta {
    background: #666;
    background-image: linear-gradient(to bottom, #fff, #cccccc);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    margin: 0px 10px;
}

.secondary-cta:hover,
.secondary-cta:focus {
    background: #fff;
    text-decoration: none;
    color: #000000;
}

.secondary-cta:first-child {
    margin-left: 0px;
}

@media (max-width: 800px) {
    #contact-form .form-group div {
        padding-left: 15px;
    }
    #contact-form .phone div:first-child {
        padding-left: 15px;
    }
    #contact-form .phone div:last-child {
        padding-right: 15px;
    }
    #contact-form .phone div {
        padding-left: 0px;
    }
}

@media (max-width: 750px) {
    .primary-cta:first-child {
        margin-bottom: 10px;
    }
    .secondary-cta {
        margin-left: 0px;
        margin-right: 0px;
    }
    #contact-form .radio-title,
    #contact-form .checkbox-title,
    #contact-form .details label {
        text-align: left;
    }
    #contact-form .details label {
        min-width: 35px;
    }
}

@media (max-width: 638px) {
    #contact-form .radio-buttons .radio-holder,
    #contact-form .checkboxes .checkbox-holder,
    #contact-form .details .details-input {
        padding-left: 5px;
    }
    #contact-form .radio-buttons .required,
    #contact-form .checkboxes .required,
    #contact-form .details .required {
        padding-left: 10px;
    }
}

@media (max-width: 540px) {
    #contact-form .longer-title {
        width: 60px;
    }
}

@media (max-width: 320px) {
    #contact-form .contact-form-main,
    #contact-form .contact-form-info,
    #contact-form .contact-form-wrapper,
    #contact-form .contact-form-bottom .details {
        padding-left: 0px;
        padding-right: 0px;
    }
    #contact-form .radio-buttons,
    #contact-form .checkboxes {
        margin-left: 0px;
    }
    #contact-form .radio-buttons li,
    #contact-form .checkboxes li {
        display: block;
    }
    #contact-form .form-group .required {
        padding-left: 5px;
    }
    #contact-form .contact-form-bottom-buttons button {
        margin-bottom: 10px;
    }
}

.direct-action-btn a:link,
.direct-action-btn a:visited {
    position: absolute;
    margin: 8px 0 0;
    width: 282px;
    text-align: center !important;
    background: #007c7c bottom repeat-x !important;
    /* Brand Color */
    border: 1px solid #007c7c !important;
    /* Brand Color */
    padding: 12px 8px;
    color: #fff !important;
    font-weight: normal !important;
    font-size: 1.6em !important;
}

.direct-action-btn a:hover {
    background: #007c7c !important;
}


/* Brand Color */


/*****CMI-contact-form******/


/*****style******/

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    right: 100%;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}


/*.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 2px;
    margin-right: -5px;
}*/

.dropdown-submenu>a:before {
    /*    display: block;*/
    content: "\25C4";
    /*    float: left;*/
    width: 0;
    height: 0;
    border-color: transparent;
    /* border-style: solid; */
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

ul,
ol {
    /*    margin-top: 22px;*/
}

.blck:hover {
    background-color: #000;
    color: #fff;
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #af0d1f !important;
    color: #fff !important;
    margin-bottom: 7px;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    background-color: #af0d1f;
}

.headerLinks {
    color: white;
}

.headerLinks:hover {
    background-color: #000;
    outline: none;
    color: white;
}

.treeview a {
    color: black;
    font-weight: bold;
}

.treeview a:hover {
    background-color: black;
    color: white;
    font-weight: bold;
}

/*****style******/


/*****spinner******/

.spinner input {
    text-align: right;
}

.input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 2%;
    vertical-align: middle;
    display: table-cell;
}

.input-group-btn-vertical>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px;
    margin-left: -1px;
    position: relative;
    border-radius: 0;
}

.input-group-btn-vertical>.btn:first-child {
    border-top-right-radius: 4px;
}

.input-group-btn-vertical>.btn:last-child {
    margin-top: -2px;
    border-bottom-right-radius: 4px;
}

.input-group-btn-vertical i, .input-group-btn-vertical em {
    position: absolute;
    top: 0;
    left: 4px;
}


/*****spinner******/


/*****CMI******/


/*
Cummins Global Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/


/*** Global Resets ***/

p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #333;
}

a {
    /*font-weight: 400;
    font-size: 13px;*/
    line-height: 20px;
    /*    color: #007c7c;*/
}

a:hover {
    /*    color: #003333;*/
    text-decoration: none;
    /*    background-color: #F2000D;*/
}

.sideMenu a:hover {
    /*	//background-color: #F2000D;
            //font-size:15px;*/
    text-decoration: underline;
}


/* Remove the dotted line from IE link Elements */

a:focus {
    outline: none;
}


/*** Header Styles ***/

h1 {
    font-weight: 800;
    font-size: 30px;
    line-height: 32px;
}

h2 {
    font-weight: 800;
    font-size: 23px;
    line-height: 24px;
}

h3 {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
}

h4 {
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
}


/*** Font Declarations ***/


/*** Global Button Styles ***/

.special-action-btn {
    background: #d02323;
    background-image: linear-gradient(to bottom, #d02323, #840909);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    height: 35px;
}

.special-action-btn:hover {
    background: #d02323;
    color: #fff;
    text-decoration: none;
}

a.special-action-btn,
a.special-action-btn:visited {
    text-decoration: none;
    color: #fff;
}


/*** Global Dropdown / Button Styles ***/

.btn {
    border-radius: 5px;
    border: 1px solid #999999;
}

.btn:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none;
}

.open>.dropdown-toggle.btn-default,
.btn-default:hover,
.btn-default:focus {
    background-color: #fff;
    color: #333;
}

.dropdown-menu {
    left: auto;
    /*** Bootstrap Override **/
    right: auto;
    min-width: 130%;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 2px solid #666;
    border-left: 1px solid #cccccc;
    -webkit-box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    box-shadow: 6px 6px 5px -4px rgba(0, 0, 0, 0.35);
    /*** (rgba(0, 0, 0, 0.35) sets black at 35% opacity ***/
    padding: 0px 0px;
}

.dropdown-menu li {
    border-bottom: 1px solid #cccccc;
}

.dropdown-menu li:last-child {
    border-bottom: 0px;
}

.dropdown-menu li a {
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
    color: #fff !important;
    background-color: #007c7c !important;
}

.navbar-nav>li>.dropdown-menu {
    right: 0;
    z-index: 10000;
}


/*** Global Form Element Styles ***/

.input-sm,
.form-group-sm .form-control {
    /*    font-style: italic;*/
    font-size: 12px;
    line-height: 15px;
    color: #666;
}

.input-sm,
.form-group-sm .form-control:focus {
    /*    border-color: #007c7c;*/
}


/*** Global Header Styles ***/


/*** Banner Masthead Styles ***/


/*** Footer Styles ***/

.footer {
    background-color: #000;
    margin-top: 30px;
    position: relative;
}

@media (max-width: 320px) {
    .mobile-padding-removal {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .footer {
        margin-top: 15px;
    }
}


/*****CMI******/


/*****CMI-Bannner-component******/


/*
Banner Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/

.banner-component {
    background-color: #000000;
}

.banner-component .container {
    padding-right: 0px;
    padding-left: 0px;
}

.product-banner {
    height: 245px;
    overflow: hidden;
    position: relative;
}


/*.bc-overlay {
    background: url("../images/banner/1024_banner_transp.png") no-repeat;
    width: 100%;
    height: 245px;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    display: table;
}*/

.bc-overlay span {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
    padding-left: 115px;
}

.bc-overlay span h1 {
    font-weight: 900;
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase;
    color: #fff;
    margin-top: 0px;
}

.bc-overlay span h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 25px;
    color: #fff;
    margin-top: 10px;
}

@media all and (max-width: 800px) {
    .product-banner {
        height: 191px;
        overflow: hidden;
        position: relative;
    }
    .product-banner img {
        height: 191px;
    }
    /*    .bc-overlay {
            background: url("../images/banner/800_banner_transp.png") no-repeat;
            width: 100%;
            height: 191px;
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            display: table;
        }*/
    .bc-overlay span h2 {
        max-width: 45%;
        min-width: 200px;
    }
}

@media all and (max-width: 320px) {
    .product-banner {
        height: 76px;
        overflow: hidden;
        position: relative;
    }
    .product-banner img {
        height: 76px;
    }
    /*    .bc-overlay {
            background: url("../images/banner/320_banner_transp.png") no-repeat;
            height: 76px;
        }*/
    .bc-overlay span {
        padding-left: 65px;
        padding-right: 75px;
    }
    .bc-overlay span h1 {
        font-size: 14px;
        line-height: 15px;
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .bc-overlay span h2 {
        font-size: 12px;
        line-height: 13px;
        margin-top: 5px;
    }
}


/*****CMI-Breadcrumb-component******/


/*
Breadcrumb Component Stylesheet
Website: Cummins UX Standards Prototype
*/

.breadcrumb-component {
    background-color: #eeeeee;
    margin-bottom: 30px;
}

.breadcrumb-component .btn-default {
    font-weight: 300;
    color: #007c7c;
    border-bottom: 1px solid #C3C3C3!important;
    background-color: #eeeeee;
}

.breadcrumb-component .btn-group a div {
    border-bottom: 1px solid #C3C3C3;
}

.breadcrumb-component .btn-group a:last-child {
    color: #666666;
}

.breadcrumb-component .btn-group a:last-child div {
    border-bottom: 0px;
}

.breadcrumb-component .btn-group .btn>div {
    margin-left: 5px;
}

.breadcrumb-component .btn-group .active div {
    border-bottom: 0px!important;
}

.breadcrumb-component .active {
    color: #666666;
    box-shadow: none;
}

.breadcrumb-component .row .btn-group>.btn:hover {
    z-index: inherit!important;
}

.btn-breadcrumb .btn:not(:last-child):after {
    content: " ";
    display: block;
    border: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 100%;
    z-index: 3;
}

.btn-breadcrumb .home:before {
    content: " ";
}

.btn-breadcrumb .btn:not(:last-child):before {
    content: url("/assets/css/images/breadcrumb_arrow.png");
    display: block;
    width: 0;
    height: 0;
    border: 0;
    position: absolute;
    /*    top: 1em;*/
    left: 100%;
    z-index: 3;
}

.btn-breadcrumb .btn {
    padding: 10px;
    border: 0px!important;
    border-radius: 0px!important;
    margin-left: 5px;
}

.btn-breadcrumb .btn:first-child {
    padding-left: 0px;
}

.breadcrumb-component .btn-group>.btn:first-child {
    margin-left: 30px;
}

.breadcrumb-component .btn-group .home div {
    margin-left: 0px;
}


/** Default button **/

.btn-breadcrumb .btn.btn-default:not(:last-child):after {
    border: 0;
}

.btn-breadcrumb .btn.btn-default:not(:last-child):before {
    border: 0;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
    border: 0;
}

.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
    border: 0;
}


/* The responsive part */

.btn-breadcrumb>*>div {
    /* With less: .text-overflow(); */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-breadcrumb>*:nth-child {
    display: none;
}

.btn-breadcrumb>*:nth-child(n+1) {
    display: none;
}


/* Make last Breadcrumb INACTIVE link */

.not-active {
    pointer-events: none;
    cursor: default;
}


/* === For phones =================================== */

@media (max-width: 320px) {
    .breadcrumb-component {
        margin-bottom: 20px;
    }
    .breadcrumb-component .container {
        padding-left: 0px;
    }
    .breadcrumb-component .btn-group>.btn:first-child {
        margin-left: 0px;
    }
}

@media (max-width: 767px) {
    .btn-breadcrumb>*:nth-last-child(-n+2) {
        display: block;
    }
    .btn-breadcrumb>* div {
        max-width: 60px;
    }
    .btn-breadcrumb>*:nth-child(2):not(:last-child) div:before {
        content: "...";
        float: left;
    }
    .navbar-nav .open .dropdown-menu {
        min-width: 100%;
    }
}


/* === For tablets ================================== */

@media (min-width: 768px) and (max-width:991px) {
    .btn-breadcrumb>*:nth-last-child(-n+4) {
        display: block;
    }
    .btn-breadcrumb>* div {
        max-width: 200px;
    }
}


/* === For desktops ================================== */

@media (min-width: 992px) {
    .btn-breadcrumb>*:nth-last-child(-n+3) {
        display: block;
    }
    .btn-breadcrumb>* div {
        max-width: 200px;
    }
}


/*****CMI-tab-panel-component******/


/*
Tab Panel Component Stylesheet
Cummins UX Standards Template
(C)2015 Cummins Inc., Box 3005, Columbus, IN 47202-3005 U.S.A.
For questions, UI standards and more contact the Cummins UX Team
*/


/* Tabs container */

.r-tabs {
    position: relative;
    /*background-color: #eeeeee;
    border: 10px solid #eeeeee;*/
}


/* Tab element */

.r-tabs .r-tabs-nav .r-tabs-tab {
    position: relative;
    background-color: #eeeeee;
    background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
    background-image: linear-gradient(to bottom, #eeeeee, #cccccc);
    border-top: 2px solid #999999;
    border-bottom: 2px solid #999999;
}

.r-tabs .r-tabs-nav .r-tabs-tab:hover,
.r-tabs .r-tabs-nav .r-tabs-tab:hover a {
    background-color: #007C7C;
    background-image: none;
    color: #fff;
}


/* Tab anchor */

.r-tabs .r-tabs-nav .r-tabs-anchor {
    font-weight: 700;
    font-size: 13px;
    color: #333;
    display: inline-block;
    padding: 10px;
    text-decoration: none;
}


/* Disabled tab */

.r-tabs .r-tabs-nav .r-tabs-state-disabled {
    opacity: 0.5;
}


/* Active state tab anchor */

.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
    color: #000000;
    text-shadow: none;
    background-color: white;
}

.r-tabs .r-tabs-nav .r-tabs-state-active {
    border-top: 2px solid #007C7C;
    border-bottom: 2px solid #fff;
}


/* Tab panel */

.r-tabs .r-tabs-panel {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    background-color: white;
    padding: 10px;
    display: none;
}


/* Accordion anchor */

.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
    display: block;
    padding: 10px;
    background-color: #00c5ad;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;
}


/* Active accordion anchor */

.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
    background-color: #fff;
    color: #000000;
}


/* Disabled accordion button */

.r-tabs .r-tabs-accordion-title.r-tabs-state-disabled {
    opacity: 0.5;
}

.r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}


/* Accordion responsive breakpoint */

@media only screen and (max-width: 320px) {
    .r-tabs .r-tabs-nav {
        display: none;
    }
    .r-tabs .r-tabs-accordion-title {
        display: block;
        border-top: 2px solid #999999;
    }
    .r-tabs .r-tabs-state-active {
        border-top: 2px solid #007c7c;
    }
    .r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
        background-color: #fff;
        color: #000000;
        background-image: none;
    }
    .r-tabs .r-tabs-panel {
        border-top: 0;
        font-weight: 300;
    }
    .r-tabs .r-tabs-accordion-title .r-tabs-anchor {
        background-color: #eeeeee;
        background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
        background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
        background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
        background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
        background-image: linear-gradient(to bottom, #eeeeee, #cccccc);
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #333;
    }
    .r-tabs .r-tabs-state-active .r-tabs-anchor {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
    }
    .r-tabs .r-tabs-accordion-title .r-tabs-anchor:hover {
        background-color: #007C7C;
        background-image: none;
        color: #fff;
    }
    .r-tabs-accordion-title a:after {
        content: "";
        background-image: url("/assets/icons/toggle-arrow-left-nav.png");
        background-repeat: no-repeat;
        float: left;
        padding-right: 25px;
        width: 15px;
        height: 15px;
        background-position: 0 -15px;
    }
    .r-tabs-accordion-title a:hover:after {
        /* symbol for "opening" panels */
        background-image: url("/assets/icons/toggle-arrow-left-nav-wht.png");
    }
    .r-tabs-accordion-title.r-tabs-state-active a:after {
        background-position: 0 0px;
    }
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid red;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/*****CMI-tab-panel-component******/

.ng-valid[required],
.ng-valid.required {
    border-left: 5px solid #42A948;
    /* green */
}

.error-msg {
    color: #fff;
}

.dropdown-menu>li>a {
    cursor: pointer;
}

.breadcrumb {
    margin-bottom: 0px;
}

.find-esn .box-header {
    border-bottom: 2px solid red;
}

.find-esn h3 {
    color: red;
}

.saved-esn img {
    height: 120px !important;
}

.saved-esn i {
    font-size: 20px;
    border: 1px solid;
    border-radius: 10px;
    cursor: pointer;
}

.saved-esn ul li>a {
    cursor: pointer;
}


/* .image-container img{
    height:200px;
    margin-left:-15px;
}*/

.image-container-dpf img-xhr div img {
    height: 400px !important;
    margin-left: -15px
}

img[alt^='Need Training?'] {
    height: 80px;
    width: 80px;
}
